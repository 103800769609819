import { createSlice } from '@reduxjs/toolkit';
import { apiToken, callApiStart, configEndpoint, configFlowSetup, INIT, signupPartnerBuilding, signupPartnerGetBuilding, profilePaymentBuilding, partnerGetBuilding, m2mAuthorizeBuilding, logout, notificationPostBuilding, notificationGetBuilding, configureGetBuilding, documentGetBuilding, documentUserGetBuilding, documentUserPatchBuilding, documentUserDeleteBuilding, oidcRedirectBuilding, authorizePostBuilding, authorizeGetBuilding } from './actions';
import { APIStore } from './store';
import { APIDataInit } from './types';

const initialState: APIStore = {
  request: {},
  response: {},
  flowAPI:{
    status: 'idle'
  },
  apiCall: {
    signupPartnerGet: APIDataInit(),
  }
};

const apiStore = createSlice({
  name: 'api',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    signupPartnerGetBuilding(
      signupPartnerBuilding(
        profilePaymentBuilding(
          partnerGetBuilding(
            m2mAuthorizeBuilding(
              notificationGetBuilding(
                notificationPostBuilding(
                  configureGetBuilding(
                    documentGetBuilding(
                      documentUserGetBuilding(
                        documentUserPatchBuilding(
                          documentUserDeleteBuilding(
                            oidcRedirectBuilding(
                              authorizePostBuilding(
                                authorizeGetBuilding(
                                  builder
                                )
                              )
                            )
                          )
                        )
                      )
                    )
                  )
                )
              )
            )
          )
        )
      )
    )
    .addCase(callApiStart, (state, action) => {
      state.request = action.payload
      state.response = {}
    })
    .addCase(apiToken, (state, action) => {
      state.token = action.payload
      localStorage.setItem("token", JSON.stringify(action.payload))
    })
    .addCase(logout, (state, action) => {
      state.token = undefined
      localStorage.removeItem("token")
    })
    .addCase(configEndpoint, (state, action) => {
      if (state.api && action.payload.environments.length > 0) {
        state.api.endpoint = `https://${action.payload.environments[0].url}/`
        localStorage.setItem("url", state.api.endpoint)
      }
    })
    .addCase(configFlowSetup, (state, action) => {
      if (state.api) {
        state.flowAPI.status = action.payload.state
      }
    })
    .addCase(INIT, (state, action) => {
      if (!state.api) {
        const defaultUri = window.location.hostname.replace(/ui-saas\.voiceme-([a-z]+)\.demo\.infra\.voiceme\.id/, "https://api.voiceme-$1.demo.infra.voiceme.id/")
        state.api = {
          confirm: "voiceme/profile/confirm/",
          document: "voiceme/document-user/",
          endpoint: localStorage.getItem("url") || defaultUri,
          enroll: "voiceme/enroll/",
          authorize: "voiceme/authorize/",
          m2mAuthorize: "voiceme/m2m/authorize/",
          notifications: "voiceme/notifications/",
          oidc: "voiceme/oidc/authorize/",
          partner: "voiceme/partner/",
          partnerSignup: "voiceme/signup/partner/",
          profilePayment: "voiceme/profile/payment/",
          signup: "voiceme/signup/",
        }
      }
      const tokenString = localStorage.getItem("token")
      if(tokenString) {
        state.token = JSON.parse(tokenString)
      }
    })
  },
});

export default apiStore.reducer;
